<!-- 哆玩电竞 奖励政策 -->
<template>
  <div class="app_container">
    <div class="img">
      <img src="../assets/pic.png" alt="">
    </div>
  </div>
</template>

<script>
//import x from ''
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar{
  display: none;
}
.app_container {
 .img{
  width: 100%;
  img{
    width: 100%;
    vertical-align: bottom;
  }
 }
}
</style>
