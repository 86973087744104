import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//自定义公共样式
import "./style/publicStyle.scss"  
// px转rem插件 
import 'amfe-flexible'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
